<template>
  <div class="columns">
    <div class="column is-6 is-offset-3">
      <p>A default score of 4 will be applied for each item.</p>
      <p>You can edit the score for each student as required.</p>
      <p>To edit go to ATE Result Psychomotors page.</p>
      <button
        type="submit"
        class="button btn-120 is-primary is-capitalized is-pulled-right mt-5"
        @click="initializeFastPsychomotors()"
      >
        Submit
      </button>
    </div>
    <!-- Delete -->
    <app-modal
      id="apply-fast-psychomotors"
      context="update"
      @update="update"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add"
        ><p>
          You are about to apply the default psychomotor value to all students.
        </p>
        <p>Students who already have psychomotor will be ignored</p>
        <p>Proceed?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  data() {
    return {
      pageTitle: 'Fast Psychomotors',
    }
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setSubMenus', [
      {
        name: 'School Classes',
        route: `/school/${this.schoolId}/school_classes`,
      },
    ])
  },
  methods: {
    initializeFastPsychomotors() {
      this.openModal()
    },
    update() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation fastPsychomotors($schoolId: Int!) {
              fastPsychomotors(input: { schoolId: $schoolId }) {
                errors
              }
            }
          `,
          variables: {
            schoolId: parseInt(this.$route.params.school_id),
          },
        })
        .then((response) => {
          if (response.data.fastPsychomotors.errors.length === 0) {
            this.closeModal()
            this.$buefy.notification.open({
              duration: 5000,
              message: 'Successfully applied',
              position: 'is-top',
              type: 'is-info',
              hasIcon: true,
            })
            this.$router.push(`/school/${this.schoolId}/result_psychomotors`)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
